import React from "react";
import AppRoutes from "./AppRoutes";

export default function MainLayout() {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}
