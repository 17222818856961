import { Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoIosCheckmark } from "react-icons/io";
import { TfiClose } from "react-icons/tfi";
import { VscTerminalCmd } from "react-icons/vsc";
import processes from "../../data/processes";

export default function AuctaConsole() {
  const [changeConsoleColor, setChangeConsoleColor] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isMinimized, setIsMinimized] = useState(true);
  const startDate = new Date(2019, 3, 18);
  const currentDate = new Date();

  const diffTime = currentDate - startDate;
  const diffDate = new Date(diffTime);

  const uptimeYears = diffDate.getUTCFullYear() - 1970;

  useEffect(() => {
    if (isMobile) {
      setIsMinimized(true);
    }
  }, [isMobile]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key.toLowerCase() === "c") {
        event.stopPropagation();
        setIsMinimized((prevState) => !prevState);
      } else if (event.key === "Escape") {
        setIsMinimized(true);
      }
    };
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMinimized, changeConsoleColor]);

  const toggleMinimized = () => {
    setIsMinimized(!isMinimized);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (isMinimized) {
      if (position > 60) {
        setChangeConsoleColor(true);
      } else {
        setChangeConsoleColor(false);
      }
    } else {
      if (position > 150) {
        setChangeConsoleColor(true);
      } else {
        setChangeConsoleColor(false);
      }
    }
  };

  return (
    <>
      {!isMinimized ? (
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            right: !isMobile && "20px",
            left: isMobile && "10px",
            width: isMinimized
              ? "130px"
              : !isMinimized && !isMobile
              ? "430px"
              : !isMinimized && isMobile
              ? window.innerWidth - 20 + "px"
              : "430px",
            height: isMinimized
              ? "60px"
              : !isMinimized && !isMobile
              ? "310px"
              : !isMinimized && isMobile
              ? "340px"
              : "310px",
            backgroundColor: !changeConsoleColor ? "#fff" : "#080808",
            color: "#d4d4d4",
            boxShadow: !changeConsoleColor
              ? "0 4px 6px rgba(0, 0, 0, 0.1)"
              : "0 10px 12px -2px rgba(255, 255, 255, 0.05), 0 4px 5px -3px rgba(255, 255, 255, 0.05), 8px 0 10px -2px rgba(255, 255, 255, 0.05), -8px 0 10px -2px rgba(255, 255, 255, 0.05)",
            borderRadius: "5px",
            transition: "all 0.3s",
            zIndex: 999,
          }}
        >
          <div
            style={{
              height: "25px",
              margin: "0 auto",
              backgroundColor:
                !isMinimized && (!changeConsoleColor ? "#f5f5f5" : "#3a3b3d"),
              display: "flex",
              alignItems: "center",
              position: "sticky",
              top: 0,
              zIndex: 1,
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
            }}
          >
            <Typography
              style={{
                color: !changeConsoleColor
                  ? "rgba(14, 14, 14, 0.6)"
                  : "rgba(255, 255, 255, 0.6)",
                fontSize: "12px",
                marginLeft: "10px",
                letterSpacing: "0.5px",
                fontFamily: "HelveticaNowDisplay-Bold",
              }}
            >
              Console (C)
            </Typography>
            <TfiClose
              size={12}
              color="#080808"
              onClick={toggleMinimized}
              style={{
                position: "absolute",
                right: "7px",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            />
          </div>
          <div
            style={{
              textAlign: "left",
              padding: "0 5px",
              margin: "10px",
              borderRadius: "3px",
              height: "200px",
            }}
          >
            <div
              style={{
                fontFamily: "Consolas",
                color: !changeConsoleColor
                  ? "#080808"
                  : "rgba(255, 255, 255, 0.8)",
                fontSize: "13px",
                fontWeight: 600,
              }}
            >
              <b>aucta %</b> service status
              <br />
              <Typography
                style={{
                  fontWeight: "normal",
                  fontFamily: "Consolas",
                  fontSize: "13px",
                  marginTop: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span style={{ width: "30%" }}>Status</span>
                <span
                  style={{
                    width: "40%",
                    textAlign: "center",
                  }}
                >
                  Name
                </span>
                <span style={{ width: "20%", textAlign: "right" }}>
                  Completion
                </span>
              </Typography>
              <hr />
              {processes.map((process) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <IoIosCheckmark
                    style={{
                      fontSize: "20px",
                      marginRight: "5px",
                      color: "#10c124",
                    }}
                  />
                  <span
                    style={{
                      width: "30%",
                      fontWeight: "normal",
                      fontSize: "14px",
                    }}
                  >
                    {process.status}
                  </span>
                  <span
                    style={{
                      width: "40%",
                      textAlign: "center",
                      fontWeight: "normal",
                      fontSize: "14px",
                    }}
                  >
                    {process.name}
                  </span>
                  <span
                    style={{
                      width: "20%",
                      textAlign: "right",
                      fontWeight: "normal",
                      fontSize: "14px",
                    }}
                  >
                    {process.duration}
                  </span>
                </div>
              ))}
              <hr />
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IoIosCheckmark
                    style={{
                      fontSize: "20px",
                      marginRight: "5px",
                      color: "#10c124",
                    }}
                  />
                  4 of 4 checks have passed successfully (100%)
                </div>
                🏁 Total uptime: {uptimeYears} years
              </div>
              <br />
              <span style={{ color: "#815ac0" }}>$</span> whoami
              <br />
              <span
                style={{
                  fontWeight: "normal",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/logo.png`}
                  style={{
                    width: "15px",
                    height: "15px",
                    marginRight: "5px",
                  }}
                />
                aucta.dev - Your trusted partner in innovation
              </span>
              <span style={{ color: "#815ac0" }}>$</span> contact --help
              <br />
              <span style={{ fontWeight: "normal", fontSize: "14px" }}>
                Email:{" "}
                <a
                  href="mailto:contact@aucta.com"
                  onClick={(e) => {
                    const mailtoLink = e.target.getAttribute("href");
                    const mailtoWindow = window.open(mailtoLink);

                    if (
                      !mailtoWindow ||
                      mailtoWindow.closed ||
                      typeof mailtoWindow.closed === "undefined"
                    ) {
                      e.preventDefault();
                      const fallbackMailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=contact@aucta.com&su=Aucta%20Contact&body=Hello%20Aucta%20Team`;
                      window.location.href = fallbackMailLink;
                    }
                  }}
                >
                  contact@aucta.dev
                </a>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            width: "60px",
            height: "50px",
            backgroundColor: !changeConsoleColor ? "#fff" : "#080808",
            color: "#d4d4d4",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "15px",
            transition: "all 0.3s",
            zIndex: 999,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            border: !changeConsoleColor
              ? "1px solid rgba(36, 34, 34, 0.1)"
              : "2px solid rgba(253, 252, 252, 0.13)",
            cursor: "pointer",
          }}
          onClick={toggleMinimized}
        >
          <VscTerminalCmd
            size={25}
            color={!changeConsoleColor ? "#171718" : "rgba(255, 251, 251, 0.7)"}
          />
        </div>
      )}
    </>
  );
}
