const processes = [
  {
    status: "Active",
    name: "Client Satisfaction",
    duration: "100%",
  },
  {
    status: "In Progress",
    name: "Project Delivery",
    duration: "100%",
  },
  {
    status: "In Progress",
    name: "Quality of Work",
    duration: "100%",
  },
  {
    status: "Active",
    name: "Customer Support",
    duration: "100%",
  },
];

export default processes;
