const projects = [
  {
    id: 1,
    title: "Distribution System",
    name: "Central Registry of the Republic of North Macedonia",
    year: "2023",
    img: "crm.png",
    description:
      "Efficient document management, offering dynamic information packages, secure document ordering with delivery options, and electronic timestamps with QR codes for validation. Supports electronic certificates and guarantees secure access to important documents for users.",
  },
  {
    id: 2,
    title: "Ferry Booking System",
    name: "Asta Adria Ferry Agents Group",
    year: "2023",
    img: "Asta-Adria-logo.gif",
    description:
      "Reliable solution designed to streamline the management of ferry operations. This project integrates real-time tracking, automated scheduling, and order management to provide an efficient, reliable, and user-friendly service for ferry operators and passengers. ",
  },
  {
    id: 3,
    title: "Portal for e-Services",
    name: "Agency of Real Estate Cadastre",
    year: "2024",
    img: "akn.png",
    description:
      "GIS Portal - Platform that offers electronic services for land and property management. Simplifies access to cadastral documents, property registration, providing a streamlined, digital interface to improve the efficiency and transparency of real estate transactions and services.",
  },
  {
    id: 4,
    title: "E-Portal",
    name: "Office for management of registers of births, marriages and deaths",
    year: "2021",
    img: "uvmk.png",
    description:
      "Platform that enables citizens and institutions to access and manage civil registration services digitally. Provides a streamlined approach for handling vital records such as births, marriages, and deaths, ensuring efficient processing, transparency, and ease of access to essential civil data.",
  },
  {
    id: 5,
    title: "Electronic System for Public Procurements",
    name: "Public Procurement Bureau",
    year: "2021",
    img: "e-pazar.png",
    description:
      "Streamlined electronic commerce and government services, enabling businesses and consumers to access a wide range of online services. By fostering transparency, efficiency, and secure transactions, it simplifies business registration and other commercial processes.",
  },
  {
    id: 6,
    title: "E-commerce Platform",
    name: "Speelgoedzaak",
    year: "2021",
    img: "speelgoedzaak.png",
    description:
      "Fully automated online store specializing in toys for children. Provides a seamless shopping experience, offering a wide selection of products, from educational toys to outdoor games. Efficiently manages orders, inventory, and customer interactions, assuring quick delivery and smooth service.",
  },
];

export default projects;
