import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoIosStar, IoIosStarOutline } from "react-icons/io";

export default function WhyChooseUs() {
  const [isStarFilled, setIsStarFilled] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsStarFilled((prev) => !prev);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} className="why-choose-us-grid">
      <Grid container id="why-choose-us-container">
        <Grid item xs={1.5} />
        <Grid item xs={9} id="why-choose-us-content">
          <Typography id="why-choose-us-header">
            Why choose us
            <sup id="why-choose-us-header-sup">
              {"["}{" "}
              {isStarFilled ? (
                <IoIosStar
                  size={23}
                  style={{ marginLeft: "2px", transition: "0.3s" }}
                />
              ) : (
                <IoIosStarOutline
                  size={23}
                  style={{ marginLeft: "2px", transition: "0.3s" }}
                />
              )}
              {"]"}
            </sup>
          </Typography>
          <Typography id="why-choose-us-text">
            We are a trusted software development partner committed to{" "}
            <span
              style={{
                background: "linear-gradient(to right, #ff7e5f, #feb47b)",
                color: "transparent",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                display: "inline-block",
                lineHeight: "1.2",
                fontFamily: "HelveticaNowDisplay-Regular",
              }}
            >
              providing transparent and efficient cooperation models.
            </span>{" "}
            At the heart of our approach is ensuring you receive exactly what
            you expect, within your budget and on time. We are dedicated to
            delivering your projects and achieving success together. With Aucta,
            you have the flexibility to choose the{" "}
            <span
              style={{
                background: "linear-gradient(to right, #7b2cbf, #a2d2ff)",
                color: "transparent",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                display: "inline-block",
                lineHeight: "1.2",
                fontFamily: "HelveticaNowDisplay-Regular",
                WebkitTextFillColor: "transparent",
              }}
            >
              level of involvement that suits you.
            </span>{" "}
            Whether you prefer full control over the project or prefer us to
            take the lead, we offer various cooperation models to meet your
            needs.
          </Typography>
        </Grid>
        <Grid item xs={1.5} />
      </Grid>
    </Grid>
  );
}
