import React, { useEffect } from "react";

export default function LoadingComponent({ onAssetsLoaded }) {
  useEffect(() => {
    const imgs = [
      `${process.env.PUBLIC_URL}/assets/img/grid1.png`,
      `${process.env.PUBLIC_URL}/assets/img/grid2-dark.png`,
      `${process.env.PUBLIC_URL}/assets/img/grid3-dark.png`,
      `${process.env.PUBLIC_URL}/logo.png`,
    ];

    const fonts = [
      { url: `${process.env.PUBLIC_URL}/assets/fonts/Consolas.TTF` },
      {
        url: `${process.env.PUBLIC_URL}/assets/fonts/HelveticaNowDisplay-Bold.ttf`,
      },
      {
        url: `${process.env.PUBLIC_URL}/assets/fonts/HelveticaNowDisplay-Regular.ttf`,
      },
      {
        url: `${process.env.PUBLIC_URL}/assets/fonts/HelveticaNowDisplay-Light.ttf`,
      },
      {
        url: `${process.env.PUBLIC_URL}/assets/fonts/InstrumentSerif-Italic.ttf`,
      },
      { url: `${process.env.PUBLIC_URL}/assets/fonts/Inter-Bold.ttf` },
      { url: `${process.env.PUBLIC_URL}/assets/fonts/Inter-Light.ttf` },
      { url: `${process.env.PUBLIC_URL}/assets/fonts/Inter-Regular.ttf` },
    ];

    const cacheImages = (srcArray) => {
      return Promise.all(
        srcArray.map((src) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = resolve;
            img.onerror = reject;
          });
        })
      );
    };

    const cacheFonts = (fontArray) => {
      return Promise.all(
        fontArray.map(({ url }) => {
          const fontFace = new FontFace("CustomFont", `url(${url})`);
          return fontFace.load().then((loadedFont) => {
            document.fonts.add(loadedFont);
          });
        })
      );
    };

    const preloadAssets = async () => {
      const startTime = performance.now();
      try {
        await Promise.all([cacheImages(imgs), cacheFonts(fonts)]);
      } catch (error) {
        console.error(error);
      }
      const elapsedTime = performance.now() - startTime;
      const minDelay = 1500;
      if (elapsedTime < minDelay) {
        setTimeout(() => onAssetsLoaded(), minDelay - elapsedTime);
      } else {
        onAssetsLoaded();
      }
    };

    preloadAssets();
  }, [onAssetsLoaded]);

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="loader"></div>
    </div>
  );
}
