import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { FaLinkedin } from "react-icons/fa";

export default function Footer() {
  return (
    <Grid
      item
      xs={12}
      id="footer-main-grid"
      style={{
        background: `url(${process.env.PUBLIC_URL}/assets/img/footer.png)`,
      }}
    >
      <Grid container id="footer-content-grid">
        <Grid item xs={9} id="footer-header-company">
          <div id="company-logo-name">
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt=""
              style={{
                width: "30px",
                height: "30px",
                opacity: "0.8",
              }}
            />
            <Typography id="company-name">Aucta Development</Typography>
          </div>
        </Grid>
        <Grid item xs={9} id="footer-items-grid">
          <div>
            <Typography className="footer-item-header">Address</Typography>
            <Typography
              sx={{
                fontFamily: "HelveticaNowDisplay-Light",
                color: "rgb(255, 254, 254)",
                fontSize: "17px",
                cursor: "pointer",
                ":hover": {
                  color: "#008cff",
                },
              }}
              onClick={() => {
                window.open(
                  "https://maps.app.goo.gl/tZwk2dYV89dKyAzD7",
                  "_blank"
                );
              }}
            >
              Naroden Front 31/1-7, Skopje
            </Typography>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Typography className="footer-item-header">Contact</Typography>
            <Typography
              sx={{
                fontFamily: "HelveticaNowDisplay-Light",
                color: "rgb(255, 254, 254)",
                fontSize: "17px",
                cursor: "pointer",
                ":hover": {
                  color: "#008cff",
                },
              }}
            >
              contact@aucta.dev
            </Typography>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Typography className="footer-item-header">Social</Typography>
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                cursor: "pointer",
                marginTop: "5px",
                "&:hover": {
                  color: "#008cff",
                },
              }}
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/auctadev",
                  "_blank"
                );
              }}
            >
              <FaLinkedin size={25} />
            </Box>
          </div>
        </Grid>
        <Grid item xs={9} id="footer-divider-line"></Grid>
        <Grid item xs={9} id="footer-all-rights-grid">
          <Typography id="footer-all-rights-text">
            ©{new Date().getFullYear()} Aucta Development. All rights reserved.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
