import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import projects from "../../data/projects";

export default function FeaturedWork() {
  const [projectHovered, setProjectHovered] = useState(1);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      className="featured-work-grid"
      sx={{
        background: `url(${process.env.PUBLIC_URL}/assets/img/featuredWork.png) center center no-repeat`,
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
    >
      <Grid container>
        <Grid item xs={1.5} />
        <Grid item xs={9} id="featured-work-title">
          <Typography id="featured-work-title-text">
            <span id="featured-work-title-first-letter">F</span>
            eatured work
          </Typography>
          <sup id="featured-work-header-sup">
            {"("}
            {projects.length}
            {")"}
          </sup>
        </Grid>
        <Grid item xs={1.5} />
        <Grid item xs={1.5} />
        <Grid item xs={9} id="projects">
          {projects.map((project) => (
            <div
              key={project.id}
              className={`project-div ${
                project.id === projectHovered ? "active" : ""
              }`}
              onMouseEnter={() => setProjectHovered(project.id)}
            >
              <Grid item xs={2.5} id="project-name-grid">
                <Typography
                  className={`project-numbering ${
                    project.id === projectHovered ? "active" : ""
                  }`}
                >
                  0{project.id}
                </Typography>
                <Typography
                  className={`project-name ${
                    project.id === projectHovered ? "active" : ""
                  }`}
                  style={{
                    fontSize: window.innerWidth >= 1280 ? "1.3em" : "0.9em",
                  }}
                >
                  {project.name}
                </Typography>
              </Grid>
              <Grid item xs={1.5} id="project-year-grid">
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/assets/img/${project.img}`}
                  style={{
                    width: "40px",
                    height: "40px",
                    filter: "invert(150%) grayscale(150%) brightness(150%)",
                  }}
                />
              </Grid>
              <Grid item xs={3} id="project-title-grid">
                <Typography
                  className={`project-title ${
                    project.id === projectHovered ? "active" : ""
                  }`}
                  style={{
                    fontSize: window.innerWidth >= 1280 ? "1.1em" : "0.9em",
                  }}
                >
                  <span
                    className={`project-title-underline ${
                      project.id === projectHovered ? "active" : ""
                    }`}
                  />
                  {project.title}
                </Typography>
              </Grid>
              {project.id === projectHovered && (
                <Grid item xs={5} id="project-description-grid">
                  <Typography
                    className="project-description"
                    style={{
                      fontSize: window.innerWidth >= 1280 ? "1.1em" : "0.8em",
                    }}
                  >
                    {project.description}
                  </Typography>
                </Grid>
              )}
            </div>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
