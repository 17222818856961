import { Grid, Typography } from "@mui/material";
import React from "react";
import { BiLogoSpringBoot } from "react-icons/bi";
import { FaCloud, FaDatabase, FaPeopleGroup } from "react-icons/fa6";
import { GiProcessor } from "react-icons/gi";
import { GrSettingsOption } from "react-icons/gr";
import { TiFlowSwitch } from "react-icons/ti";

export default function ServicesMobile() {
  return (
    <Grid
      item
      xs={12}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        textAlign: "left",
        padding: "20px",
        background: `url(${process.env.PUBLIC_URL}/assets/img/grid2-dark.png) center center no-repeat`,
        backgroundSize: "cover",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{
              fontFamily: "HelveticaNowDisplay-Light",
              fontSize: "25px",
              color: "#fff",
            }}
          >
            <span
              style={{
                backgroundColor: "#ef233c",
                padding: "0 5px",
              }}
            >
              W
            </span>
            hat we do
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <div
            style={{
              border: "2px solid rgba(122, 117, 117, 0.1)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              borderRadius: "10px",
              boxShadow:
                "0 2px 1px rgba(0, 0, 0, 0.09), 0 4px 2px rgba(0, 0, 0, 0.09), 0 8px 4px rgba(0, 0, 0, 0.09), 0 16px 8px rgba(0, 0, 0, 0.09),0 32px 16px rgba(0, 0, 0, 0.09)",
            }}
          >
            <Typography
              style={{
                fontFamily: "Inter-Bold",
                fontSize: "20px",
                color: "rgba(255, 255, 255, 0.8)",
                padding: "10px",
                letterSpacing: "0.5px",
              }}
            >
              Custom Business Solutions
            </Typography>
            <Typography
              style={{
                fontFamily: "HelveticaNowDisplay-Light",
                fontSize: "14px",
                color: "rgba(255, 255, 255, 0.5)",
                padding: "0 20px",
              }}
            >
              Our custom software solutions are meticulously designed to address
              the unique requirements of your business. We understand that every
              organization has its own set of challenges and objectives, which
              is why we create tailored systems that align perfectly with your
              workflows and goals.
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/assets/img/final.png`}
                style={{
                  width: "270px",
                  height: "140px",
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          marginTop: "10px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", gap: "5px" }}>
          <Grid
            item
            xs={6}
            style={{
              backgroundColor: "#9cb1ff",
              height: "120px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: "HelveticaNowDisplay-Regular",
                color: "#fff",
                fontSize: "35px",
              }}
            >
              50+
            </Typography>
            <Typography
              style={{
                fontFamily: "Inter-Regular",
                fontSize: "15px",
                color: "#fff",
                lineHeight: "15px",
              }}
            >
              Technologies supported
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              backgroundColor: "#395ee3",
              height: "120px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/img/iso.png`}
              style={{
                width: "90px",
                height: "20px",
                marginRight: "5px",
              }}
            />
            <Typography
              style={{
                fontFamily: "HelveticaNowDisplay-Regular",
                color: "#fff",
                fontSize: "18px",
              }}
            >
              9001, 27001, 20000-1, 22301
            </Typography>
          </Grid>
        </div>
        <div style={{ display: "flex", gap: "5px" }}>
          <Grid
            item
            xs={6}
            style={{
              height: "120px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              border: "1px solid #333",
            }}
          >
            <Typography
              style={{
                fontFamily: "HelveticaNowDisplay-Regular",
                color: "#fff",
                fontSize: "30px",
                fontWeight: 600,
              }}
            >
              30+
            </Typography>
            <Typography
              style={{
                fontFamily: "Inter-Regular",
                fontSize: "15px",
                margin: "0 15px",
                color: "#fff",
                lineHeight: "17px",
              }}
            >
              Enterprise-level projects delivered successfully
            </Typography>
          </Grid>
          <Grid item xs={6} id="box6mobile">
            <Typography
              style={{
                fontFamily: "HelveticaNowDisplay-Regular",
                color: "#fff",
                fontSize: "30px",
                fontWeight: 600,
              }}
            >
              24/7
            </Typography>
            <Typography
              style={{
                fontFamily: "Inter-Regular",
                fontSize: "12px",
                margin: "0 15px",
                color: "#fff",
                lineHeight: "17px",
              }}
            >
              Security Monitoring
            </Typography>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "10px" }}>
        <div
          style={{
            border: "2px solid rgba(122, 117, 117, 0.1)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            borderRadius: "10px",
            boxShadow:
              "0 2px 1px rgba(0, 0, 0, 0.09), 0 4px 2px rgba(0, 0, 0, 0.09), 0 8px 4px rgba(0, 0, 0, 0.09), 0 16px 8px rgba(0, 0, 0, 0.09),0 32px 16px rgba(0, 0, 0, 0.09)",
            paddingBottom: "20px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Inter-Bold",
              fontSize: "20px",
              color: "rgba(255, 255, 255, 0.8)",
              padding: "10px",
              letterSpacing: "0.5px",
            }}
          >
            System Integration
          </Typography>
          <Typography
            style={{
              fontFamily: "HelveticaNowDisplay-Light",
              fontSize: "14px",
              color: "rgba(255, 255, 255, 0.5)",
              padding: "0 20px",
            }}
          >
            Our System Integration services enable seamless communication and
            collaboration across your entire IT ecosystem. We specialize in
            connecting disparate systems, applications, and platforms, ensuring
            they work together efficiently to streamline operations and improve
            business outcomes.
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
              flexWrap: "wrap",
            }}
          >
            <div class="integration-circle">
              <FaCloud
                class="icon"
                color="rgba(255, 255, 255, 0.8)"
                size={25}
              />
              <GiProcessor
                class="icon"
                color="rgba(255, 255, 255, 0.8)"
                size={25}
              />
              <FaDatabase
                class="icon"
                color="rgba(255, 255, 255, 0.8)"
                size={25}
              />
              <BiLogoSpringBoot
                class="icon"
                color="rgba(255, 255, 255, 0.8)"
                size={25}
              />
              <FaPeopleGroup
                class="icon"
                color="rgba(255, 255, 255, 0.8)"
                size={25}
              />
              <GrSettingsOption
                class="icon"
                color="rgba(255, 255, 255, 0.8)"
                size={25}
              />
              <TiFlowSwitch
                class="icon"
                color="rgba(255, 255, 255, 0.8)"
                size={25}
              />
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "10px" }}>
        <div
          id="box8"
          class="box"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Typography
            style={{
              fontFamily: "Inter-Bold",
              color: "rgba(255, 255, 255, 0.7)",
              fontSize: "20px",
              textAlign: "center",
              letterSpacing: "0.5px",
              padding: "10px 0 0 0",
            }}
          >
            Transparency
          </Typography>
          <img
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/transparency.png`}
            style={{
              width: "350px",
              height: "60px",
            }}
          />
          <Typography
            style={{
              fontFamily: "HelveticaNowDisplay-Light",
              color: "rgba(255, 255, 255, 0.5)",
              fontSize: "15px",
              textAlign: "center",
              padding: "5px 15px",
            }}
          >
            We prioritize transparency through open communication, clear
            processes, and accountability, ensuring that every step of your
            project is fully visible and aligned with your goals.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "10px" }}>
        <div style={{ display: "flex", gap: "5px" }}>
          <Grid
            item
            xs={6}
            style={{
              height: "120px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              border: "1px solid #333",
            }}
          >
            <Typography
              style={{
                fontFamily: "HelveticaNowDisplay-Regular",
                color: "rgba(255, 252, 252, 0.8)",
                fontSize: "19px",
              }}
            >
              Teams on
              <br /> demand
            </Typography>
          </Grid>
          <Grid item xs={6} id="box6mobile">
            <Typography
              style={{
                fontFamily: "HelveticaNowDisplay-Regular",
                color: "rgba(255, 252, 252, 0.8)",
                fontSize: "19px",
              }}
            >
              Rapid value
              <br /> delivery
            </Typography>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
