import { Grid, Hidden, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AuctaConsole from "./AuctaConsole";
import Services from "./Services";
import Footer from "./Footer";
import HomePageMobile from "./HomePageMobile";
import WhyChooseUs from "./WhyChooseUs";
import FeaturedWork from "./FeaturedWork";
import LoadingComponent from "../common/LoadingComponent";

export default function HomePage() {
  const [loading, setLoading] = useState(true);

  const handleAssetsLoaded = () => {
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <LoadingComponent onAssetsLoaded={handleAssetsLoaded} />
      ) : (
        <>
          <Grid
            container
            style={{
              height: "100vh",
              background: `url(${process.env.PUBLIC_URL}/assets/img/grid1.png) center center no-repeat`,
              backgroundSize: "cover",
            }}
          >
            <Hidden smDown>
              <Grid item lg={4} sm={3} md={3}>
                <Grid container>
                  <Grid item lg={4.5} xs={1} md={1} />
                  <Grid
                    item
                    lg={7.5}
                    xs={11}
                    md={11}
                    id="home-page-left-grid-content"
                  >
                    <Typography id="home-page-left-grid-content-text">
                      Tailoring{" "}
                      <span style={{ display: "block" }}>
                        Digital experiences
                      </span>
                    </Typography>
                    <Typography id="home-page-left-grid-content-since-date">
                      Since 2019
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={4} sm={6} md={6} id="home-page-center-main-grid">
                <div className="responsive-div">
                  <div className="rotating-rectangle one"></div>
                  <div className="rotating-rectangle two"></div>
                </div>
                <div id="main-grid-div">
                  <Typography id="main-grid-text">
                    <span className="animate-text">Innovate tomorrow, </span>
                    <span
                      id="main-grid-text-span"
                      className="animate-text-delayed"
                    >
                      today.
                    </span>
                  </Typography>
                  <Typography id="main-grid-subtext">
                    At <span id="main-grid-subtext-span">Aucta</span> we craft
                    solutions that empower progress and drive innovation. Our
                    mission is to turn today's ideas into tomorrow's realities.
                  </Typography>
                </div>
              </Grid>
              <Grid item lg={4} sm={3} md={3} id="home-page-right-grid">
                <Grid container>
                  <Grid
                    item
                    xs={11}
                    lg={7.5}
                    md={11}
                    id="home-page-right-logo-grid"
                  >
                    <Typography id="home-page-right-grid-text">
                      aucta.dev
                    </Typography>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/logo.png`}
                      id="home-page-right-grid-img"
                    />
                  </Grid>
                  <Grid item lg={4.5} xs={1} md={1} />
                </Grid>
              </Grid>
              <Services />
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                lg={12}
                style={{
                  padding: "40px",
                  backgroundColor: "#171718",
                }}
              />
              <WhyChooseUs />
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                lg={12}
                style={{
                  padding: "40px",
                  backgroundColor: "#171718",
                }}
              />
              <FeaturedWork />
              <Footer />
            </Hidden>
            <Hidden smUp>
              <HomePageMobile loading={loading} />
            </Hidden>
          </Grid>
          <AuctaConsole />
        </>
      )}
    </>
  );
}
