import { Grid, Hidden, Typography } from "@mui/material";
import React from "react";
import { GiProcessor } from "react-icons/gi";
import { FaDatabase } from "react-icons/fa6";
import { BiLogoSpringBoot } from "react-icons/bi";
import { FaPeopleGroup } from "react-icons/fa6";
import { GrSettingsOption } from "react-icons/gr";
import { TiFlowSwitch } from "react-icons/ti";
import { FaCloud } from "react-icons/fa6";

export default function Services() {
  return (
    <Grid
      item
      xs={12}
      id="services-main-grid"
      style={{
        background: `url(${process.env.PUBLIC_URL}/assets/img/grid2-dark.png) center center no-repeat`,
        backgroundSize: "cover",
        paddingTop: "25px",
        paddingBottom: "30px",
      }}
    >
      <Grid container id="services-inner-container">
        <Grid item xs={1.5} />
        <Grid item xs={9} id="services-title">
          <Typography id="services-title-text">
            <span id="services-title-text-first-letter">W</span>
            hat we do
          </Typography>
        </Grid>
        <Grid item xs={1.5} />
        <Grid item xs={1.5} />
        <Grid item xs={9} id="services-bento-grid">
          <div className="container">
            <div
              id="box1"
              className="box col-12 col-md-6 col-lg-6"
              style={{ position: "relative" }}
            >
              <Typography id="box1-header-text">
                Custom Business Solutions
              </Typography>
              <Typography className="box1-content-text">
                Our custom software solutions are meticulously designed to
                address the unique requirements of your business. We understand
                that every organization has its own set of challenges and
                objectives, which is why we create tailored systems that align
                perfectly with your workflows and goals.
              </Typography>
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  bottom: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/assets/img/final.png`}
                  style={{
                    maxWidth: "120%",
                    height: "auto",
                    display: "block",
                    margin: "0 auto",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
            <div
              id="box2"
              className="box col-12 col-md-6 col-lg-3"
              style={{ padding: "15px" }}
            >
              <Hidden mdDown>
                <Typography
                  style={{
                    fontFamily: "HelveticaNowDisplay-Light",
                    color: "#fff",
                    fontSize: window.innerWidth >= 1280 ? "3.2em" : "2.5em",
                    fontWeight: 600,
                    marginBottom: "-15px",
                  }}
                >
                  50+
                </Typography>
                <Typography
                  style={{
                    fontFamily: "HelveticaNowDisplay-Light",
                    fontSize: window.innerWidth >= 1280 ? "1.1em" : "0.9em",
                    color: "#fff",
                  }}
                >
                  Technologies supported
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Inter-Regular",
                    fontSize: "13.5px",
                    fontSize: window.innerWidth >= 1280 ? "13.5px" : "0.7em",
                    margin: "0 15px",
                    color: "rgba(255, 255, 255, 0.8)",
                    lineHeight: 1.1,
                  }}
                >
                  Expertise in modern frameworks, languages, and platforms like
                  React, Angular, Java, .NET, and more
                </Typography>
              </Hidden>
              <Hidden mdUp>
                <Typography
                  style={{
                    fontFamily: "HelveticaNowDisplay-Light",
                    color: "#fff",
                    fontSize: "2.5em",
                    fontWeight: 600,
                    marginBottom: "-15px",
                  }}
                >
                  50+
                </Typography>
                <Typography
                  style={{
                    fontFamily: "HelveticaNowDisplay-Light",
                    fontSize: "0.9em",
                    color: "#fff",
                    marginTop: "10px",
                    lineHeight: 1.1,
                  }}
                >
                  Technologies supported
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Inter-Regular",
                    fontSize: "0.7em",
                    margin: "0 15px",
                    color: "rgba(255, 255, 255, 0.8)",
                    lineHeight: 1.1,
                    marginTop: "10px",
                  }}
                >
                  Expertise in modern frameworks, languages, and platforms like
                  React, Angular, Java, .NET, and more
                </Typography>
              </Hidden>
            </div>
            <div id="box3" className="box col-12 col-md-6 col-lg-3">
              <Hidden mdDown>
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/assets/img/iso.png`}
                  style={{
                    maxWidth: "50%",
                    height: "auto",
                    display: "block",
                    margin: "0 auto",
                    marginTop: "13px",
                    marginBottom: "-15px",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  style={{
                    fontFamily: "HelveticaNowDisplay-Light",
                    fontSize: "23px",
                    color: "#fff",
                    marginTop: "20px",
                    fontSize: window.innerWidth >= 1280 ? "1.5em" : "1.4em",
                  }}
                >
                  9001, 27001, 20000-1, 22301
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Inter-Regular",
                    fontSize: "13.5px",
                    margin: "0 15px",
                    color: "rgba(255, 255, 255, 0.8)",
                    lineHeight: 1.1,
                  }}
                >
                  Commitment to quality and data security in every project
                </Typography>
              </Hidden>
              <Hidden mdUp>
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/assets/img/iso.png`}
                  style={{
                    maxWidth: "70%",
                    height: "auto",
                    display: "block",
                    margin: "0 auto",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  style={{
                    fontFamily: "HelveticaNowDisplay-Light",
                    fontSize: "0.9em",
                    color: "#fff",
                    marginTop: "10px",
                    lineHeight: 1.1,
                    padding: "0 10px",
                  }}
                >
                  9001, 27001, 20000-1, 22301
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Inter-Regular",
                    fontSize: "0.7em",
                    margin: "0 15px",
                    color: "rgba(255, 255, 255, 0.8)",
                    lineHeight: 1.1,
                    marginTop: "10px",
                  }}
                >
                  Commitment to quality and data security in every project
                </Typography>
              </Hidden>
            </div>
            <div id="box4" className="box col-12 col-md-6 col-lg-6">
              <Typography
                style={{
                  fontFamily: "HelveticaNowDisplay-Regular",
                  fontSize: "1.5em",
                  color: "rgba(255, 252, 252, 0.8)",
                  padding: "10px",
                  letterSpacing: "0.5px",
                }}
              >
                System Integration
              </Typography>
              <Typography
                style={{
                  fontFamily: "HelveticaNowDisplay-Light",
                  fontSize: "1.05em",
                  color: "rgba(255, 255, 255, 0.5)",
                  padding: "0 20px",
                }}
              >
                Our System Integration services enable seamless communication
                and collaboration across your entire IT ecosystem. We specialize
                in connecting disparate systems, applications, and platforms,
                ensuring they work together efficiently to streamline operations
                and improve business outcomes.
              </Typography>
              <div
                style={{
                  width: "350px",
                  bottom: 0,
                  position: "absolute",
                  left: "50%",
                  right: "50%",
                  transform: "translateX(-50%)",
                  overflow: "hidden",
                  display: "block",
                  margin: "0 auto",
                  overflow: "hidden",
                }}
              >
                <div
                  className="integration-circle"
                  style={{
                    height: window.innerWidth >= 1280 ? "200px" : "250px",
                  }}
                >
                  <FaCloud
                    className="icon"
                    color="rgba(255, 255, 255, 0.8)"
                    size={25}
                  />
                  <GiProcessor
                    className="icon"
                    color="rgba(255, 255, 255, 0.8)"
                    size={25}
                  />
                  <FaDatabase
                    className="icon"
                    color="rgba(255, 255, 255, 0.8)"
                    size={25}
                  />
                  <BiLogoSpringBoot
                    className="icon"
                    color="rgba(255, 255, 255, 0.8)"
                    size={25}
                  />
                  <FaPeopleGroup
                    className="icon"
                    color="rgba(255, 255, 255, 0.8)"
                    size={25}
                  />
                  <GrSettingsOption
                    className="icon"
                    color="rgba(255, 255, 255, 0.8)"
                    size={25}
                  />
                  <TiFlowSwitch
                    className="icon"
                    color="rgba(255, 255, 255, 0.8)"
                    size={25}
                  />
                </div>
              </div>
            </div>
            <div id="box5" className="box col-12 col-md-6 col-lg-3">
              <div className="circle-container">
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/assets/img/test.png`}
                  style={{
                    maxWidth: "60%",
                    height: "auto",
                    display: "block",
                    margin: "0 auto",
                    objectFit: "contain",
                  }}
                />
              </div>
              <Typography
                style={{
                  fontFamily: "HelveticaNowDisplay-Light",
                  color: "#fff",
                  fontSize: "3em",
                  fontWeight: 600,
                }}
              >
                30+
              </Typography>
              <Typography
                style={{
                  fontFamily: "HelveticaNowDisplay-Light",
                  fontSize: "1em",
                  color: "rgba(255, 255, 255, 0.8)",
                  margin: "0 15px",
                }}
              >
                Enterprise-level projects delivered successfully
              </Typography>
            </div>
            <div id="box6" className="box col-12 col-md-6 col-lg-3">
              <Typography
                style={{
                  fontFamily: "HelveticaNowDisplay-Light",
                  color: "#fff",
                  fontSize: "45px",
                  fontWeight: 600,
                }}
              >
                24/7
              </Typography>
              <Typography
                style={{
                  fontFamily: "HelveticaNowDisplay-Light",
                  fontSize: "21px",
                  color: "rgba(255, 255, 255, 0.8)",
                  margin: "0 15px",
                }}
              >
                Security Monitoring
              </Typography>
            </div>
            <div id="box7" className="box col-12 col-md-6 col-lg-3">
              <Typography
                style={{
                  fontFamily: "HelveticaNowDisplay-Regular",
                  color: "rgba(255, 252, 252, 0.8)",
                  padding: "1px 7px",
                  width: "100%",
                  fontSize: window.innerWidth >= 1280 ? "1.5em" : "1.1em",
                }}
              >
                Teams on demand
              </Typography>
              <Typography
                style={{
                  fontFamily: "HelveticaNowDisplay-Light",
                  color: "rgba(255, 255, 255, 0.5)",
                  fontSize: "1em",
                  textAlign: "center",
                  width: "calc(100% - 30px)",
                  marginTop: "3px",
                  fontSize: window.innerWidth >= 1280 ? "1em" : "0.8em",
                }}
              >
                Seamlessly combining a structured development approach with
                engineers integrated into your company for optimal efficiency
                and collaboration.
              </Typography>
            </div>
            <div
              id="box8"
              className="box col-12 col-md-6 col-lg-6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <Typography
                style={{
                  fontFamily: "HelveticaNowDisplay-Regular",
                  color: "rgba(255, 252, 252, 0.8)",
                  fontSize: "1.5em",
                  textAlign: "center",
                  letterSpacing: "0.5px",
                  padding: "10px 0 0 0",
                  fontSize: window.innerWidth >= 1280 ? "1.5em" : "1.2em",
                }}
              >
                Transparency
              </Typography>
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/assets/img/transparency.png`}
                style={{
                  maxWidth: "70%",
                  height: "auto",
                  display: "block",
                  margin: "0 auto",
                  objectFit: "contain",
                }}
              />
              <Typography
                style={{
                  fontFamily: "HelveticaNowDisplay-Light",
                  color: "rgba(255, 255, 255, 0.5)",
                  fontSize: "17px",
                  textAlign: "center",
                  width: "calc(100% - 60px)",
                  padding: "5px 0",
                }}
              >
                We prioritize transparency through open communication, clear
                processes, and accountability, ensuring that every step of your
                project is fully visible and aligned with your goals.
              </Typography>
            </div>
            <div id="box7" className="box col-12 col-md-6 col-lg-6">
              <Typography
                style={{
                  fontFamily: "HelveticaNowDisplay-Regular",
                  color: "rgba(255, 252, 252, 0.8)",
                  padding: "1px 7px",
                  width: "100%",
                  fontSize: window.innerWidth >= 1280 ? "1.5em" : "1.1em",
                }}
              >
                Rapid value delivery
              </Typography>
              <Typography
                style={{
                  fontFamily: "HelveticaNowDisplay-Light",
                  color: "rgba(255, 255, 255, 0.5)",
                  textAlign: "center",
                  width: "calc(100% - 30px)",
                  marginTop: "3px",
                  fontSize: window.innerWidth >= 1280 ? "1em" : "0.8em",
                }}
              >
                Achieve rapid, measurable results that drive your business
                forward, with a focus on continuous improvement for sustained
                growth and success.
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
