import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ServicesMobile from "./ServicesMobile";
import { IoIosStar, IoIosStarOutline } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa6";
import projects from "../../data/projects";
import LoadingComponent from "../common/LoadingComponent";

export default function HomePageMobile({ loading }) {
  const [isStarFilled, setIsStarFilled] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsStarFilled((prev) => !prev);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                textAlign: "left",
                padding: "20px",
                background: `url(${process.env.PUBLIC_URL}/assets/img/grid1.png) center center no-repeat`,
                backgroundSize: "cover",
                height: "100vh",
              }}
            >
              <Typography
                style={{
                  fontFamily: "HelveticaNowDisplay-Light",
                  fontSize: "21px",
                  textTransform: "uppercase",
                }}
              >
                Tailoring
                <br /> Digital experiences
              </Typography>
              <Typography
                style={{
                  fontFamily: "HelveticaNowDisplay-Light",
                  fontSize: "16px",
                }}
              >
                Since 2019
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                padding: "20px",
              }}
            >
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/logo.png`}
                style={{ width: "40px", height: "40px", marginLeft: "5px" }}
              />
              <Typography
                id="main-grid-text"
                style={{
                  fontFamily: "HelveticaNowDisplay-Regular",
                  fontSize: "30px",
                  margin: 0,
                  color: "#080808",
                  zIndex: 1,
                  marginTop: "-10px",
                }}
              >
                <span className="animate-text">Innovate tomorrow, </span>
                <span id="main-grid-text-span" className="animate-text-delayed">
                  today.
                </span>
              </Typography>
              <Typography
                id="main-grid-subtext"
                style={{
                  fontSize: "16px",
                  color: "#080808",
                }}
              >
                At{" "}
                <span
                  style={{
                    backgroundColor: "#ef233c",
                    padding: "3px",
                    color: "#fff",
                    fontSize: "18px",
                  }}
                >
                  Aucta
                </span>{" "}
                we craft solutions that empower progress and drive innovation.
                Our mission is to turn today's ideas into tomorrow's realities.
              </Typography>
            </Grid>
            <ServicesMobile />
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              lg={12}
              style={{
                padding: "40px",
                backgroundColor: "#171718",
              }}
            />
            <Grid
              container
              style={{
                height: "50vh",
                background: `url(${process.env.PUBLIC_URL}/assets/img/grid3-dark.png) center center no-repeat`,
                backgroundSize: "cover",
                padding: "0 0 20px 0",
              }}
            >
              <Grid item xs={1} />
              <Grid
                item
                xs={10}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  marginTop: "20px",
                  textAlign: "left",
                  padding: "10px 20px",
                  backgroundImage:
                    "linear-gradient(to bottom,rgb(30, 30, 31) 1px, transparent 1px)",
                  backgroundSize: "5px 5px",
                  overflow: "visible",
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "HelveticaNowDisplay-Light",
                    fontSize: "25px",
                    color: "#fff",
                    textAlign: "left",
                    marginBottom: "15px",
                    wordWrap: "break-word",
                  }}
                >
                  Why choose us
                  <sup
                    style={{
                      fontSize: "18px",
                      color: "#ff7e5f",
                      marginBottom: "0",
                      marginLeft: "6px",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    {"["}only{" "}
                    {isStarFilled ? (
                      <IoIosStar
                        size={23}
                        style={{ marginLeft: "2px", transition: "0.3s" }}
                      />
                    ) : (
                      <IoIosStarOutline
                        size={23}
                        style={{ marginLeft: "2px", transition: "0.3s" }}
                      />
                    )}
                    {"]"}
                  </sup>
                </Typography>
                <Typography
                  style={{
                    fontFamily: "HelveticaNowDisplay-Regular",
                    fontSize: "3.5vw",
                    color: "rgba(255, 255, 255, 0.8)",
                    lineHeight: "1.4",
                    textAlign: "left",
                    wordWrap: "break-word",
                  }}
                >
                  We are a trusted software development partner committed to{" "}
                  <span
                    style={{
                      background: "linear-gradient(to right, #ff7e5f, #feb47b)",
                      color: "transparent",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      display: "inline",
                      fontFamily: "HelveticaNowDisplay-Regular",
                      wordBreak: "break-word",
                    }}
                  >
                    providing transparent and efficient cooperation models.
                  </span>{" "}
                  At the heart of our approach is ensuring you receive exactly
                  what you expect, within your budget and on time. We are
                  dedicated to delivering your projects and achieving success
                  together. With Aucta, you have the flexibility to choose the{" "}
                  <span
                    style={{
                      background: "linear-gradient(to right, #7b2cbf, #a2d2ff)",
                      color: "transparent",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      display: "inline",
                      fontFamily: "HelveticaNowDisplay-Regular",
                      WebkitTextFillColor: "transparent",
                      wordBreak: "break-word",
                    }}
                  >
                    level of involvement that suits you.
                  </span>{" "}
                  Whether you prefer full control over the project or prefer us
                  to take the lead, we offer various cooperation models to meet
                  your needs.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              lg={12}
              style={{
                padding: "40px",
                backgroundColor: "#171718",
              }}
            />
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                textAlign: "left",
                padding: "0 20px",
                background: `url(${process.env.PUBLIC_URL}/assets/img/grid2-dark.png) center center no-repeat`,
                backgroundSize: "cover",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography id="featured-work-title-text-mobile">
                    <span id="featured-work-title-first-letter-mobile">F</span>
                    eatured work
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  {projects.map((project) => (
                    <div
                      key={project.id}
                      style={{
                        borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                        padding: "10px 0",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography className="project-numbering-mobile">
                          0{project.id}
                        </Typography>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/img/${project.img}`}
                          style={{
                            width: "30px",
                            height: "30px",
                            filter:
                              "invert(150%) grayscale(150%) brightness(150%)",
                          }}
                        />
                      </div>
                      <Typography className="project-title-mobile">
                        {project.title}
                      </Typography>
                      <Typography className="project-name-mobile">
                        {project.name}
                      </Typography>
                      <Typography className="project-description-mobile">
                        {project.description}
                      </Typography>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              lg={12}
              style={{
                padding: "40px",
                backgroundColor: "#171718",
              }}
            />
            <Grid
              container
              style={{
                height: "30vh",
                background: `url(${process.env.PUBLIC_URL}/assets/img/footer-black.png) center center no-repeat`,
                backgroundSize: "cover",
                backgroundPosition: "top",
              }}
            >
              <Grid item xs={1} />
              <Grid
                item
                xs={10}
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyItems: "flex-start",
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/logo.png`}
                    alt=""
                    style={{
                      width: "30px",
                      height: "30px",
                      opacity: "0.8",
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: "HelveticaNowDisplay-Regular",
                      color: "rgba(255, 255, 255, 0.8)",
                      fontSize: "18px",
                      marginLeft: "10px",
                    }}
                  >
                    Aucta Development
                  </Typography>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Typography
                    style={{
                      fontFamily: "HelveticaNowDisplay-Regular",
                      color: "rgba(122, 119, 119, 1)",
                      fontSize: "14px",
                    }}
                  >
                    Address
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "HelveticaNowDisplay-Light",
                      color: "rgb(255, 254, 254)",
                      fontSize: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.open(
                        "https://maps.app.goo.gl/tZwk2dYV89dKyAzD7",
                        "_blank"
                      );
                    }}
                  >
                    Naroden Front 31/1-7, Skopje
                  </Typography>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Typography
                    style={{
                      fontFamily: "HelveticaNowDisplay-Regular",
                      color: "rgba(122, 119, 119, 1)",
                      fontSize: "14px",
                    }}
                  >
                    Contact
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "HelveticaNowDisplay-Light",
                      color: "rgb(255, 254, 254)",
                      fontSize: "15px",
                    }}
                  >
                    contact@aucta.dev
                  </Typography>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <FaLinkedin
                    size={25}
                    onClick={() => {
                      window.open(
                        "https://www.linkedin.com/company/auctadev",
                        "_blank"
                      );
                    }}
                    style={{
                      color: "#008cff",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1} />
              <Grid
                item
                xs={10}
                style={{
                  height: "1px",
                  alignItems: "center",
                  borderImage:
                    "linear-gradient(to right, #9cb1ff 0%, #008cff 100%) 1",
                  borderRadius: "5px",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  marginTop: "15px",
                }}
              ></Grid>
              <Grid item xs={1} />
              <Grid item xs={1} />
              <Grid
                item
                xs={10}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter-Light",
                    color: "rgba(255, 254, 254, 0.8)",
                    fontSize: "13px",
                  }}
                >
                  ©{new Date().getFullYear()} Aucta Development. All rights
                  reserved.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
